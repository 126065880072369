*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  font-family: 'Poppins', serif;
}

#root {
  background-color: #070707;
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 1vh;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3d3d3d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(100, 100, 100);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0e0e0e;
}