#Team {
  width: 100vw;
  background-image: url("https://wallpapercave.com/wp/wp7749573.jpg");

  .team-backdropFilter {
    display: flex;
    justify-content: center;
    width: 100vw;
    backdrop-filter: blur(10px);

    .team-overview {
      display: flex;
      flex-direction: column;
      margin: 20px;
      padding: 10px;
      border-radius: 8px;

      .team-rank {
        margin-bottom: 20px;

        ul {
          list-style: none;
          padding: 0;

          li {
            display: flex;
            flex-direction: row;
            padding: 10px;
            margin: 5px 0;
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(255,255,255,0.1);

            max-width: 40rem;

            h3 {
              font-size: 1.2em;
              margin: 0 0 5px 0;
            }

            div {
              display: flex;
              flex-direction: column;
            }

            img {
              width: 6rem;
              height: 6rem;
            }
          }
        }
      }
    }
  }
}


