#CityBuild {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;

  .info-box .feature-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 400px;
  }

  .section-start {
    min-height: 800px;

    & .text-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      width: 40rem;
      min-height: 20rem;
      gap: 1.2rem;

      & h1 {
        font-size: calc(0.6vw + 2rem) !important;
        color: var(--main-color);
        font-weight: bold;
      }
    }

    & img {
      max-width: calc(6vw + 14rem);
      filter: drop-shadow(0 0 10px var(--main-color));
    }
  }

  & .text-box {
    justify-content: center;
    text-align: left;
    gap: 2rem;

    & h1 {
      font-weight: bold;
      filter: drop-shadow(0 0 4px var(--main-color));
    }
  }

  & .stats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
    background: var(--stats-background);
    padding: 20px;
    border-radius: 5px;
    max-width: 80vw;

    & .stat {
      display: flex;
      flex-direction: row;
      gap: 15px;
      justify-content: center;
      align-items: center;

      padding: 5px;
      border-radius: 5px;

      transition: 0.4s;

      &:hover {
        background: var(--hover-color);

        transform: scale(1.05);
        cursor: pointer;
      }

      & .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 5px;
        background: var(--stat-icon-background-2);
        padding: 10px;
        transition: .2s ease-in-out;

        & i,
        img{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          font-size: 20px;
          position: relative;
          color: var(--white-color);
          border-radius: 5px;

          object-position: center;
          object-fit: fill;
        }
      }

      & .texts {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 18rem;
        & .text {
          padding: 4px;
        }

        & h5 {
          color: var(--white-color);
          text-transform: uppercase;
          font-size: 17px;
          font-weight: 700;
        }

        & p {
          color: var(--description-color);
          font-size: 15px;
          font-weight: 400;

          & span {
            color: var(--green-color);
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;

    .join {
      background: var(--how-to-join-button-background);
      border: 2px solid var(--description-color);
      border-radius: 3px;
      padding: 5px 10px;
      color: var(--description-color);
      font-weight: 600;
      cursor: pointer;
      transition: 0.2s;
      width: 100%;
      i {
        font-size: calc(0.4rem + 1vw);
      }
    }
  }
}