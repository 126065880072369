#HeaderNav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 8rem 0 8rem;

  width: 100%;
  height: 80px;
  left: 0;
  top: 0;

  overflow: hidden;
  background: #24272B;

  font-family: 'Inter', serif !Important;

  z-index: 10;
   * {
     z-index: 10;
   }
}


.HeaderNavLogo a{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 8px;

  order: 0;
  flex-grow: 0;

  width: max-content;
  text-decoration: underline transparent;
}

.HeaderNavLogo img{
  width: 42px;
  height: 42px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.HeaderNavLogo h1 {
  font-weight: 900;
  font-size: 1.2rem !important;
  color: #FFFFFF;
}

#HeaderNav .nav-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
}

#HeaderNav .nav-link:hover {
  filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.1));
}

#HeaderNav .nav-item .active {
  font-weight: 500;
  filter: brightness(1.2);
}

#HeaderNav .nav-item .active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  width: 40px;
  height: 3px;
  border-radius: 5px;
  background: var(--main-color);
}

.miniLogo {
  width: 12px;
  height: 12px;
}

.navbar-toggler {
  padding: 5px 5px !important;
  & .navbar-toggler-icon {
    color: white;
  }
}

#navbarToggle{
  top: 80px;
  width: 100%;
  height: auto;
  left: 0;
  z-index: 10;
  background: var(--background-color);
  flex-direction: column;
  filter: drop-shadow(0 10px 4px rgba(0, 0, 0, 0.25));
  & * {
    text-align: left;
  }

}

@media only screen and (max-width: 1000px) {
  #HeaderNav .navbar-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
  #HeaderNav .nav-link {
    width: 100vw;
    &:hover {
      background: var(--hover-color);
    }
  }
  #HeaderNav {
    padding: 0 4rem 0 4rem;
  }
  .HeaderNavGroupLeft{
    width: max-content;
    gap: 4px;
  }
  .HeaderNavLogo h1 {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .HeaderNavGroupLeft{
    gap: 4px;
  }
  .HeaderNavSearchbar{
    display: none;
  }
  .HeaderNavLoggedIn{
    gap: 0;
  }
}